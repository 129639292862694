import Vue from 'vue';
import App from './App.vue';
import i18n from './i18n';
import Vant from 'vant';
import 'vant/lib/index.css';
import router from './router';
import http from './http';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import common from './common/function'
import 'swiper/swiper-bundle.css'
import store from './store'


import 'video.js/dist/video-js.css'



Vue.prototype.$http = http
Vue.prototype.common = common
Vue.config.productionTip = false
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
Vue.use(Vant);
new Vue({
  i18n,
  store,
  router,
  created() {
    this.$router.beforeEach((to, from, next) => {
      console.log(to.meta.titleKey)
      const titleKey = to.meta.titleKey || 'index';
      document.title = this.$t(titleKey);
      next();
    });
  },
  render: h => h(App),
}).$mount('#app')
