import Vue from 'vue'
import VueRouter from 'vue-router'
//首页
import Home from '../pages/home/index.vue' /* 首页 */
import Mine from '../pages/mine/index.vue' /* 我的 */
import Choose from '../pages/choose/index.vue' /* 选妃 */
import List from '../pages/choose/list.vue' /* 选妃列表 */
import Profile from '../pages/choose/profile.vue' /* 选妃详情 */
import Video from '../pages/video/index.vue' /* 视频 */
import Game from '../pages/game/index.vue' /* 游戏 */
import Login from '../pages/login/index.vue' /* 登录 */
import Register from '../pages/login/register.vue' /* 注册 */
import ServiceOnline from '../pages/mine/ServiceOnline.vue' /* 客服列表 */
import ServicePage from '../pages/mine/ServicePage.vue' /* 客服详情界面 */
import Setting from '../pages/mine/Setting.vue' /* 设置 */
import Infomation from '../pages/mine/Infomation.vue' /* 基本信息 */
import Setname from '../pages/mine/Setname.vue' /* 修改姓名 */
import Setsex from '../pages/mine/Setsex.vue' /* 修改性别 */
import SetPayPassword from '../pages/mine/SetPayPassword.vue' /* 修改提现密码 */
import SetLoginPassword from '../pages/mine/SetLoginPassword.vue' /* 修改提现密码 */
import Lottery from '../pages/lottery/index.vue' /* 彩票详情 */
import Notice from '../pages/mine/Notice.vue' /* 公告 */
import PlayVideo from '../pages/video/PlayVideo' /* 视频播放页面 */
import Setbank from '../pages/mine/Setbank' /* 视频播放页面 */
import BindCard from '../pages/mine/BindCard' /* 绑定银行卡界面 */
import Withdraw from '../pages/mine/Withdraw' /* 绑定银行卡界面 */
import Personalreport from '../pages/mine/Personalreport' /* 个人表报 */
import GameRecord from '../pages/mine/GameRecord' /* 游戏记录 */
import WithdrawRecord from '../pages/mine/WithdrawRecord' /* 提现记录 */
import Account from '../pages/Account/index.vue' /* 账户明细 */


Vue.use(VueRouter)
const routes = [


    { path: '/', redirect: '/Home', component: Home, meta: { titleKey: 'footer.home' } },
    { path: '/Home', name: 'home', component: Home, meta: { titleKey: 'footer.home' } },
    { path: '/Choose', name: 'choose', component: Choose, meta: { titleKey: 'choose' } },
    { path: '/List', name: 'list', component: List, meta: { titleKey: 'list' } },
    { path: '/Profile', name: 'profile', component: Profile, meta: { titleKey: 'profile' } },
    { path: '/Mine', name: 'mine', component: Mine, meta: { titleKey: 'mine' } },
    { path: '/Video', name: 'video', component: Video, meta: { titleKey: 'video' } },
    { path: '/Game', name: 'game', component: Game, meta: { titleKey: 'game' } },
    { path: '/Login', name: 'login', component: Login, meta: { titleKey: 'login.login' } },
    { path: '/Register', name: 'register', component: Register, meta: { titleKey: 'register.register' } },
    { path: '/ServiceOnline', name: 'ServiceOnline', component: ServiceOnline, meta: { titleKey: 'serviceOnline' } },
    { path: '/ServicePage', name: 'ServicePage', component: ServicePage, meta: { titleKey: 'serviceOnline'} },
    { path: '/Setting', name: 'Setting', component: Setting, meta: { titleKey: 'setting' } },
    { path: '/Infomation', name: 'Infomation', component: Infomation, meta: { titleKey: 'information' } },
    { path: '/Setname', name: 'Setname', component: Setname, meta: { titleKey: 'setName' } },
    { path: '/Setsex', name: 'Setsex', component: Setsex, meta: { titleKey: 'setSex' } },
    { path: '/SetPayPassword', name: 'SetPayPassword', component: SetPayPassword, meta: { titleKey: 'setPayPassword' } },
    { path: '/SetLoginPassword', name: 'SetLoginPassword', component: SetLoginPassword, meta: { titleKey: 'setLoginPassword' } },
    { path: '/Lottery', name: 'Lottery', component: Lottery, meta: { titleKey: 'lotteryDetails' } },
    { path: '/Notice', name: 'Notice', component: Notice, meta: { titleKey: 'notice' } },
    { path: '/PlayVideo', name: 'PlayVideo', component: PlayVideo, meta: { titleKey: 'playVideo' } },
    { path: '/Setbank', name: 'Setbank', component: Setbank, meta: { titleKey: 'setBank' } },
    { path: '/BindCard',name:'BindCard',component:BindCard,meta:{titleKey:'setBank'}},
    { path: '/Withdraw', name: 'Withdraw', component: Withdraw, meta: { titleKey: 'withdraw' } },
    { path: '/Personalreport', name: 'Personalreport', component: Personalreport, meta: { titleKey: 'personalReport' } },
    { path: '/WithdrawRecord', name: 'WithdrawRecord', component: WithdrawRecord, meta: { titleKey: 'withdrawRecord' } },
    { path: '/GameRecord', name: 'GameRecord', component: GameRecord, meta: { titleKey: 'gameRecord' } },
    { path: '/Account', name: 'Account', component: Account, meta: { titleKey: 'account' } },

];

//生成路由实例
const router = new VueRouter({
    routes
})
router.beforeEach((to, from, next) => {
    document.title = to.meta.title || 'index';
    next();
});

export default router